<template>
  <!--begin::Card-->
  <div>
    <h4 class="mb-10 font-weight-bold text-dark">
      Link your domain <br />
      <small
        >You can link your domain to your website in this section.</small
      >
    </h4>
    <form
      class="form"
      novalidate="novalidate"
      id="domain_form"
      ref="form"
      v-on:submit="save"
    >
      <div class="form-group">
        <label>Domain</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          name="custom_domain"
          placeholder="Enter Your domain"
          v-model="form.custom_domain"
          ref="custom_domain"
        />
        <span class="form-text text-muted"
          >Please enter your domain e.g abc.com</span
        >
      </div>

      <div class="d-flex flex-row-reverse" v-if="form.domain_verify == 'not_verified'">
        <button
            ref="form_submit"
            class="btn btn-primary mt-3 right"
        >
            Save
        </button>
      </div>

    </form>

    <div v-if="form.domain_verify == 'pending'">
      <p>Complete the following steps and click verify.</p>
      <p>Please add an "A" record entry for "@" points to "52.56.94.238" in the DNS.</p>
      <p>Please add an "A" record entry for "api" points to "18.170.145.174" in the DNS.</p>
      <div class="d-flex flex-row-reverse">
        <button
            ref="form_submit"
            class="btn btn-primary mt-3 right"
            @click="verify"
        >
            Verify
        </button>
      </div>
    </div>

    <div v-if="form.domain_verify == 'verified'">
        <p>You are using {{ form.custom_domain }} as your primary domain.</p>
    </div>

  </div>
  <!--end::Card-->
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
  text-align: start ;
}
.pac-target-input {
  padding: 10px;
  width: 20%;
}
.btn {
  margin-left: 20px;
  padding: 10px 20px;
  background-color: greenyellow;
}
</style>
<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";

export default {
  name: "Domain",
  data() {
    return {
      form: {
        custom_domain: '',
        domain_verify: 0
      },
      isValid: false
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    imgURL ()
    {
      return process.env.VUE_APP_API_BASE_URL;
    }
  },
  mounted() {
    this.get();
    const form = KTUtil.getById("domain_form");
    this.fv = formValidation(form, {
      fields: {
        form: {
          custom_domain: {
            validators: {
              notEmpty: {
                message: "Domain is required"
              },
              regexp: {
                flags: 'g',
                message: "Enter a valid domain",
                regexp: "(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]"
              }
            }
          },
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
    //console.log(this.fv.on("core.form.valid"));
    this.fv.on("core.form.valid", () => {
      this.$emit("validation", true);
    });

    this.fv.on("core.form.invalid", () => {
      this.$emit("validation", false);
    });
  },
  methods: {
    get() {
      ApiService.get("business/settings", "domain")
        .then(({ data }) => {
          this.form = data.data;
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    },
    save() {
        if (!this.form.custom_domain) {
            Swal.fire("Error!", "Please enter domain name", "error");
            return;
        }
        let currentUserId = this.currentUser.id;

        ApiService.post("business/settings/domain/store/"+currentUserId, this.form)
        .then(({ data }) => {
          Swal.fire("Success", "Domain added", "success");
          this.form = data.data;
          return true;
        })
        .catch(({ response }) => {
          return false;
        });
    },
    verify() {
      let currentUserId = this.currentUser.id;
      ApiService.post(
        "business/settings/domain/verify/" + currentUserId,
        this.form
      )
      .then(({ data }) => {
        this.form = data.data;
        if(data.data.domain_verify == "verified"){
          Swal.fire("Success", "Domain verified successfully", "success");
        }else{
          Swal.fire("Error!", "Domain Not Verified", "error");
        }
        return true;
      })
      .catch(({ response }) => {
        return false;
      });
    },
    onSubmit() {
      console.log(this.form.custom_domain);
      if (this.form.custom_domain == '' )
      {
        Swal.fire("Error!", "All fields are required", "danger");
        return;
      }
      else {
        this.$refs.form_submit.click();
      }
    }
  }
};
</script>
